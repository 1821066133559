.container {
    max-width: 1720px;
}

.section-label {
    font-size: pxToEm(45);
    padding-bottom: pxToRem(30);
}
/*
--------------------
Modules
--------------------
*/

