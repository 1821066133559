/* Module: S */
.locations-list {
    display: block;
}

.location {
    &__item {
        float: left;
        text-align: left;
        width: calc(50% - 20px);
        margin: 0 10px 7%;
        &:nth-child(2n + 1) {
            clear: left;
        }
    }
}