/* Module: S */
.home-news {
    &__wrapper {
        max-width: 1650px;
    }
}

.news {
    &__item {
        width: calc(25% - 20px);
    }
}