/* Module: S */
.subpage-banner {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 120px;
    min-height: pxToEm(291);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-start;

    &__wrapper {
        position: relative;
        z-index: 2;
        width: 100%;
    }

    &__title {
        font-size: pxToRem(40);
        font-weight: 300;
        margin: 0;
        line-height: 1.2;
        margin-bottom: 20px;
        &.blue {
            color: $base-font-color;
        }
        &.pink {
            color: $color-secondary;
        }
        &.white {
            color: $alt-font-color;
        }
    }

    &__date{
        color: $alt-font-color;
        font-size: pxToEm(14);
        z-index: 3;
        margin-bottom: 20px;
    }

    &:before {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+75 */
        background: -moz-linear-gradient(left,  rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 75%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 75%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 75%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        height: 100%;
        width: 100%;
    }
}

.breadcrumbs {
    list-style: none;
    color: $color-btn-text;
    width: 100%;
    font-weight: 300;
    padding-left: 0;
    margin: 0;
    padding-bottom: pxToEm(40);
    overflow: hidden;
    &.blue {
        color: $base-font-color;
    }
}

.breadcrumbs__item {
    padding: 0 pxToEm(6);
    font-size: pxToEm(16);
    padding-left: 0;
    float: left;
    &:after {
        padding-left: pxToEm(6);
        content: '>';
        float: right;
    }
    &:last-of-type {
        &:after {
            content: '';
        }
    }
    a {
        color: $color-btn-text;
        text-decoration: none;
        cursor: pointer;
    }
    &.blue {
        a {
            color: $base-font-color;
        }
    }
}