/* Module: S */
.contact-page-subtitle {
    padding: 6% 0 2% 0;

    &__wrapper {
        width: pxToEm(315);
        margin: 0 auto;
    }

    &__head {
        font-size: pxToEm(24);
        font-weight: 300;
        margin: 0;
        padding-bottom: 0;
        color: $color-primary;
    }

    &__title {
        color: $color-secondary;
        font-size: pxToEm(14);
        font-weight: 300;
    }
}