/* Module: S */
h2 {
    font-size: pxToRem(24);
    font-weight: 300;
    margin-bottom: 0;
    padding-bottom: 0;
    color: $color-primary;
}

h3 {
    font-size: pxToRem(18);
    font-weight: 300;
    margin-bottom: 0;
    padding-bottom: pxToRem(5);
    color: $color-secondary;
}

h4 {
    font-size: pxToRem(16);
    font-weight: 700;
    margin-bottom: 0;
    padding-bottom: pxToRem(5);
    color: $color-primary;
}

h5 {
    font-size: pxToRem(14);
    font-weight: 300;
    margin-bottom: 0;
    padding-bottom: pxToRem(5);
    color: $color-primary;
}

h6 {
    font-size: pxToRem(13);
    font-weight: 700;
    margin-bottom: 0;
    padding-bottom: pxToRem(5);
    color: $color-primary;
}

.text-content {
    padding: 6% 0;
    &__wrapper {
        max-width: pxToRem(315);
        margin: 0 auto;
    }
    &__title {
        font-size: pxToRem(14);
        font-weight: 500;
        margin: 0;
        padding-bottom: 0;
        color: $color-secondary;
    }
    p {
        font-size: pxToRem(13);
        font-weight: 500;
        //margin: 0;
        //padding-bottom: pxToRem(10);
        color: $color-primary;
        line-height: pxToRem(20);
    }

    ul {
        font-size: pxToRem(13);
        font-weight: 500;
        margin: 0;
        padding-bottom: pxToRem(15);
        color: $color-primary;
        line-height: pxToRem(20);
        list-style: circle outside url('{{file_dest}}/list-style-image.png');
        padding-left: pxToRem(18);
    }
    li {
        padding-left: pxToRem(12);
        padding-bottom: pxToRem(12);
    }
}

blockquote {
    font-size: pxToRem(16);
    font-weight: 300;
    margin: 0;
    margin-top: pxToRem(50);
    padding-bottom: pxToRem(15);
    color: $color-primary;
    line-height: pxToRem(25);
    position: relative;
    padding-left: pxToRem(20);
    &:before {
        font-size: pxToRem(300);
        font-weight: 500;
        color: $color-secondary;
        content: "\201C";
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1em;
        opacity: 0.3;
        margin-top: pxToRem(-80);
        margin-left: pxToRem(-20);
    }
}