/* Module: S */
@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.loader {
    overflow-y: hidden;

    .key-contacts {
        visibility: hidden;
    }
}

.key-contacts {
    margin-bottom: 60px;

    @include rwd-to($rwd-mobile-small) {
        margin-bottom: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: $color-primary;
        font-weight: 600;
    }

    &--single {
        .key-contacts__card {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex: unset;
            max-width: unset;

            @include rwd-to($rwd-tablet-xs) {
                flex-direction: column;
                margin: 0 auto 25px;
            }
        }

        .key-contacts__info-wrapper {
            margin-top: auto;
            margin-left: 35px;

            @include rwd-to($rwd-tablet-xs) {
                margin: 0;
            }
        }

        .key-contacts__card-text {
            text-align: left;

            @include rwd-to($rwd-tablet-xs) {
                text-align: center;
            }
        }

        .key-contacts__button {
            padding: 10px 50px;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include rwd-to($rwd-mobile-small) {
            flex-direction: column;
        }
    }

    &__heading {
        margin-bottom: 20px;
        font-size: 24px;
    }

    &__card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-width: 500px;
        flex: 0 0 45%;

        @include rwd-to($rwd-mobile-small) {
            flex: 0 0 100%;
            flex-direction: column;
            margin-bottom: 2.5rem;
        }
    }

    &__photo {
        max-width: 500px;
        max-height: 350px;
        height: 100%;
        object-fit: cover;
        width: 100%;
        aspect-ratio: 500/350;

        @include rwd-from($rwd-mobile-small) {
            min-height: 350px;
        }
    }

    &__info-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__card-text {
        text-align: center;
        margin: 20px 0;
    }

    &__button {
        color: $color-primary;
        font-size: 18px;
        border: 1px solid $color-primary;
        padding: 10px 22px;
        background-color: $base-bg-color;
        border-radius: 50px;
        font-weight: 700;
        transition: 0.2s all ease-in;
        display: flex;
        margin: 0 auto;

        &:hover {
            color: $alt-font-color;
            background-color: $color-primary;
        }

        @include rwd-to($rwd-mobile-small) {
            font-size: 14px;
        }
    }

    &__form-heading {
        font-size: 36px;
        line-height: 29px;
        font-weight: 700;
        margin: 10px 0;
        padding-bottom: 10px;

        @include rwd-to($rwd-tablet-xs) {
            font-size: 24px;
            line-height: 20px;
        }
    }

    &__form-close-btn {
        outline: none;
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        position: absolute;
        right: 31px;
        top: 31px;
        padding: 10px;

        &:focus-within {
            outline: 2px solid $color-primary;
        }
    }

    &__form-subheading {
        font-weight: 400;
        font-size: 18px;
        line-height: 29px;

        @include rwd-to($rwd-tablet-xs) {
            font-size: 14px;
            line-height: 19px;
        }
    }

    &__textarea {
        min-height: 110px;
        display: block;
        width: 100%;
        resize: none;
        padding: 22px 30px;
        margin-bottom: 26px;

        @include rwd-to($rwd-tablet-xs) {
            padding: 15px 30px;
            min-height: 100px;
        }
    }

    form {
        overflow-y: auto;
        max-height: 100%;
        min-height: 250px;
        max-width: 800px;
        width: 100%;
        background: $base-bg-color;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 35px 30px;
        z-index: 100;
        opacity: 1;
        border-radius: 15px;
        box-shadow: 4px 4px 14px 9px rgba(163, 163, 163, 0.25);
        display: none;

        .sq-form-section-title,
        .sq-form-required-field-note,
        .sq-form-question-title {
            display: none;
        }

        .sq-form-section {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            flex-direction: row;
            border: none;
            padding: 0;
            margin: 0;
        }

        .sq-form-question {
            flex: 0 0 49%;
            max-width: 49%;
            margin-bottom: 26px;

            @include rwd-to($rwd-tablet-xs) {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 15px;
            }

            &-email-address {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        #sq_form_field_wrapper_q226901_q4 {
            order: 9;
        }

        #sq_form_field_wrapper_q226901_q5 {
            order: 10;
            margin-bottom: 0;
        }

        .sq-form-question-answer input,
        textarea {
            width: 100%;
            border-radius: 30px;
            border: 1px solid $color-primary;
            color: $color-primary;
            font-size: 18px;

            @include rwd-to($rwd-tablet-xs) {
                font-size: 14px;
            }

            &::placeholder {
                color: $color-primary;
            }

            &:focus-visible {
                outline: 2px solid $color-primary;
            }
        }

        .sq-form-question-answer input {
            padding: 10px 30px;
        }

        .button--small {
            margin: 0 auto;
            display: block;
            font-size: 18px;
        }
    }

    .input-error {
        border: 1px solid red !important;
    }

    form.form-sent {
        display: flex;
        align-items: center;
        justify-content: center;

        .key-contacts__form-container {
            display: none;
        }
    }

    .form-open {
        display: block;
        animation: fade-in 0.3s ease-in;
    }
}
