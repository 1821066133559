/* Module: S */
.contact-box {
    background-color: $color-primary;
    text-align: center;

    &__wrapper {
        padding: 4% $mobile-side-padding 6%;
    }

    &__text {
        color: $alt-font-color;
        font-size: pxToEm(24);
        font-weight: 300;
    }
}