/* Module: S */
.project-services {
    padding: 6% 0;
    padding-top: 0;
}

.service {
    &__list {
        padding: 0 20px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    &__item {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        display:  inline-block;
        height: pxToEm(450);
        position: relative;
        margin: 0 0 30px;
        &:nth-child(3n + 1) {
            clear: left;
        }
    }

    &__link {
        text-decoration: none;
        color: $alt-font-color;
    }

    &__wrapper {
        width: 100%;
        background-color: $color-primary;
        position: absolute;
        bottom: 0;
        text-align: center;
        padding: 0 pxToEm(50);
        @include sq-transition(background-color .3s ease-in-out);
    }

    &__title {
        margin-top: pxToEm(30);
        margin-bottom: pxToEm(15);
        font-size: pxToEm(14);
        font-weight: normal;
        color: $alt-font-color;
    }

    &__text {
        font-size: pxToEm(11);
        margin-top: 0;
        margin-bottom: pxToEm(30);
        color: $alt-font-color;
    }
    &__dots {
        display: block;
        padding-bottom: pxToEm(30);
        margin: 0 auto;
    }
}