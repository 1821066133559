/* Module: S */
.contact-page-subtitle {
    &__wrapper {
        width: pxToEm(486);
    }

    &__head {
        font-size: pxToEm(45);
        padding-bottom: pxToEm(10);
    }

    &__title {
        font-size: pxToEm(20);
    }
}