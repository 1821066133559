/* Module: S */
.header {
    position: relative;
}
.top-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    padding-top: pxToEm(20);
    &.down {
        top: 108px;
    }
    &__wrapper {
        float: right;
        margin-top: 25px;
        border-bottom: none;
    }
}