/* Module: S */
.contact-box {
    &__text {
        font-size: pxToEm(45);
    }

    &__wrapper {
        padding-left: 5%;
        padding-right: 5%;
    }
}