/* Module: S */
.info-box {
    &__container.text-content__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 2.5rem auto;
        max-width: 100%;
        padding:0;

        @include rwd-to($rwd-tablet) {
            flex-wrap: wrap;
            max-width: unset;
        }

        @include rwd-to($rwd-tablet-xs) {
            margin: 1rem auto 0;
        }

        @include rwd-to($rwd-mobile) {
            margin: 0 -30px;
        }
    }

    &__container--reduced {
        @include rwd-to($rwd-mobile) {
            flex-direction: column;
        }
        .info-box__item {
            flex: 0 0 30%;
            @include rwd-to($rwd-mobile) {
                flex: 0 0 100%;
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $color-btn-text;
        flex: 0 0 23.5%;
        padding: 12px 20px;
        align-self: stretch;

        &:nth-child(odd) {
            background-color: $color-primary;
        }

        &:nth-child(even) {
            background-color: $color-secondary;
        }

        @include rwd-to($rwd-tablet-xs) {
            flex: 0 0 49%;
            margin-bottom: 1rem;
        }

        @include rwd-to($rwd-mobile) {
            flex: 0 0 100%;
            margin-bottom: 0;
        }
    }

    &__name {
        font-size: 18px;
        margin: 0;
        color: #fff;

        @include rwd-to($rwd-tablet-xs) {
            font-size: 14px;
        }
    }

    &__title {
        margin-top: 9px;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;

        @include rwd-to($rwd-tablet-xs) {
            font-size: 16px;
            line-height: 16px;
        }
    }
}
