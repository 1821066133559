/* Module: S */
.search-results__content {
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.search-results__content.container {
    max-width: 1185px;
}

.search-results__col-left {
    width: 26%;
    margin-right: 100px;
}
.search-results__col-right {
    width: 74%;
}
.filters-dropdown {
    margin-bottom: 30px;
    &:last-child {
        margin-bottom: 0;
    }
}
.dropdown-heading {
    font-size: 18px;
}

.dropdown-item {
    font-size: 16px;
}

.search-tags {
    display: flex;
    border-bottom: 1px solid $color-primary;
}

.tag-item {
    padding: 10px 70px 12px 30px;
    font-size: 18px;
    margin-bottom: 0;
}

.tag__close-button {
    right: 0.875rem;
    top: 0.6875rem;
}

.jobs-list {
    margin-top: 30px;
}
.job-result {
    padding: 30px;
    &__title {
        margin-top: 0px;
        font-size: 24px;
        font-weight: 300;
    }
    &__location {
        font-size: 18px;
        font-weight: 700;
    }
    &__description {
        font-size: 16px;
    }
    &__more {
        float: right;
        font-size: 1.125em;
    }
}