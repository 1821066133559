/* Module: S */
.header {
    position: relative;
}
.top-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    padding-top: pxToEm(20);

    &__wrapper {
        float: right;
    }
}

@media (min-width: 1300px) {
    .top-bar__wrapper {
        border-bottom: 1px solid $base-bg-color;
    }
}