/* Module: S */
.home-news {
    &__wrapper {
        max-width: 586px;
    }
}

.news {

    &__item {
        float: left;
        width: calc(50% - 20px);
        margin: 0 10px 3%;
        &:nth-child(4n + 1) {
            clear: left;
        }
    }

    &__link {

        &:hover {
            color: $alt-font-color;
            .news__wrapper {
                background-color: $color-primary;
                @include sq-transition(background-color .5s ease-in-out);
            }
            .news__title {
                color: $alt-font-color;
            }
            .news__read-more {
                color: $alt-font-color;
                // background: url('{{file_dest}}/arrow-white.svg') no-repeat right center;
            }
        }
    }

    &__wrapper {
        padding: pxToEm(30) pxToEm(20) pxToEm(20) pxToEm(20);
    }

    &__title {
        font-size: pxToEm(24);
    }

    &__date {
        margin-bottom: pxToEm(30);
        font-size: pxToEm(14);
    }

    &__text {
        font-size: pxToEm(16);
        font-weight: 300;
    }

    &__more-wrapper {
        margin-top: 0;
    }

    &__read-more {
        font-size: pxToEm(18);
        padding-right: pxToEm(20);
    }
}

.home-news .section-label {
    text-align: center;
}