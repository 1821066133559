/* Module: S */
.find-job {
    &__container {
    min-height: pxToEm(745);
    }

    &__wrapper {
        max-width: 90%;
    }

    &__head {
        font-size: pxToEm(55);
        padding-top: pxToEm(50);
    }

    &__text {
        line-height: pxToEm(34);
        font-size: pxToEm(16);
        max-width: pxToEm(805);
        margin: 0 auto
    }
    &__inputs {
        padding-bottom: pxToEm(50);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        // justify-content: center;
    }
    &__input {
        width: 393px;
        margin: 0 10px;
    }
    &__input-item {
        padding: 0 140px 0 30px;
        height: 53px;
        width: 393px;
    }
    &__button {
        &--search {
            right: 12px;
            top: 12px;
        }
        &--location {
            right: 12px;
            top: 12px;
        }
    }
}

.find-job__input .twitter-typeahead .tt-suggestion {
    font-size: pxToRem(16);
    width: pxToRem(393);
}

.find-job__location-select {
    padding: 0 140px 0 30px;
    height: 53px;
    width: 393px;
}

.find-job__input .twitter-typeahead .tt-menu {
    width: 393px;
}