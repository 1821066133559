/* Module: S */
.case-study {
    max-width: 1178px;
    margin: 0 auto;
    font-size: 18px;

    @include rwd-to(1200) {
        padding: 0 30px;
    }

    @include rwd-to($rwd-mobile) {
        font-size: 14px;
    }
}