/* Module: S */
.main-banner {
    &__wrapper {
        // padding-top: pxToEm(95);
    }

    &__title {
        width: 60%;
        font-size: pxToEm(60);        
    }
}