/* Module: S */
.main-banner {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: pxToEm(290);
    position: relative;

    &__wrapper {
        position: relative;
        z-index: 2;
        padding-top: pxToEm(135);
        padding-bottom: pxToEm(30);
    }

    &__title {
        font-size: pxToEm(36);
        font-weight: 300;
        margin: 0;
        width: 100%;
        line-height: 1.2;
        &.blue {
            color: $color-primary;
        }
        &.white {
            color: $alt-font-color;
        }
        &.pink {
            color: $color-secondary;
        }
    }

    &:before {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+75 */
        background: -moz-linear-gradient(left,  rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 75%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 75%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 75%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        height: 100%;
        width: 100%;
    }
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: top left;
        background-size: auto 100%;
    }
    &.pink {
        &:after {
            background-image: url('{{file_dest}}/top-mobile-shape-pink.svg');
        }
    }
    &.blue {
        &:after {
            background-image: url('{{file_dest}}/top-mobile-shape.svg');
        }
    }
}