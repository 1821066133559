/* Tables */
table {
    width: 100%;
    border-collapse: collapse;
    margin: .5em 0;
}

caption {
    @include sq-font-size(.8);
    font-style: italic;
}

th {
    background: $base-font-color;
    color: invert($base-font-color);
}

td {
    border: 1px solid $base-font-color;
}

/* Buttons */
.button {
    @include sq-transition(background-color .3s ease-in-out);
    background-color: $color-secondary;
    color: $color-btn-text;
    display: inline-block;
    border: 0;
    font-size: pxToEm(12);
    padding: 0.7em 1.7em;
    border-radius: 50px;
    font-weight: 500;

    &,
    &:visited {
        text-decoration: none;
    }

    &:focus,
    &:hover {
        background-color: $color-primary;
        color: $color-btn-text;
        outline: none;
    }

    &--white {
        background-color: $base-bg-color;
        color: $color-primary;

        &:hover {
            background-color: $color-secondary-light;
            color: $color-btn-text;
        }
    }
}

.button--dark {
    @include sq-transition(background-color .3s ease-in-out);
    background-color: $color-primary;
    color: $color-btn-text;
    display: inline-block;
    border: 0;
    font-size: pxToEm(12);
    padding: 0.7em 1.7em;
    border-radius: 50px;
    font-weight: 500;

    &,
    &:visited {
        text-decoration: none;
    }

    &:focus,
    &:hover {
        background-color: $color-secondary-light;
        color: $color-btn-text;
    }

    &--white {
        background-color: $base-bg-color;
        color: $color-primary;

        &:hover {
            background-color: $color-secondary-light;
            color: $color-btn-text;
        }
    }
}

.button--large {
    @include sq-font-size(1.5);
}

.button--small {
    @include sq-font-size(.8);
}

/* Quotes */
blockquote {
    position: relative;
    padding-left: 3em;
    margin: 2em 0;
    &:before {
        @include sq-font-size(5);
        color: $base-font-color;
        content: "\201C";
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1em;
    }
}

.pull-quote {
    border-top: 1px solid $base-font-color;
    padding: 1em .5em 0 .5em;
    margin: 1em;
}

/* Highlight */
.highlight {
    background: #cccccc;
    @extend %inline-block;
    padding: .1em .5em;
}