/* Module: S */
.our-people {
    padding: 6% 0;
    &__wrapper {
        max-width: pxToRem(486);
        margin: 0 auto;
    }
    &__head {
        font-size: pxToEm(45);
        font-weight: 300;
        margin: 0;
        padding-bottom: 0;
        color: $color-secondary;
    }
    &__text {
        font-size: pxToEm(18);
        padding-bottom: pxToEm(20);
        color: $color-primary;
        line-height: pxToRem(33);
        font-weight: 300;
    }
}

.our-people-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 20px;
    &__image {
        width: 100%;
        height: pxToRem(270);
        margin-bottom: pxToRem(20);
    }
    &__text-section {
        margin-bottom: pxToEm(50);
        width: 100%;
        position: relative;
    }
    &__name {
        font-size: pxToRem(31);
        font-weight: 300;
        margin: 0;
        padding-bottom: pxToRem(5);
        color: $color-secondary;
    }
    &__role {
        font-size: pxToRem(18);
        font-weight: 700;
        color: $color-primary;
        margin: 0;
    }
    &__text {
        max-height: pxToEm(235);
        overflow: hidden;
        transition: max-height 1s;
    }
    &__text p {
        font-size: pxToRem(18);
        color: $color-primary;
        line-height: pxToRem(33);
        font-weight: 300;
    }

    &__button {
        font-size: pxToRem(16);
        font-weight: 700;
        color: $color-secondary;
        position: absolute;
        bottom: pxToEm(-20);
        left: 0;
        text-decoration: none;
        display: none;
        text-align: center;
        padding: 0 pxToEm(20);
        padding-left: 0;
        z-index: 2;
        background-color: $alt-font-color;
    }

    &__accordion {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            bottom: pxToEm(-15);
            left: 0;
            width: 100%;
            height: pxToEm(40);
            z-index: 0;
            background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.82) 18%, rgba(255,255,255,0.93) 53%, rgba(255,255,255,1) 100%);
            background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.82) 18%,rgba(255,255,255,0.93) 53%,rgba(255,255,255,1) 100%);
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.82) 18%,rgba(255,255,255,0.93) 53%,rgba(255,255,255,1) 100%);
        }
    }
    &__social {
        margin-top: 20px;
    }
    &__social-link {
        margin-right: pxToRem(20);
        &:hover {
            @include sq-opacity(60);
        }
    }
}