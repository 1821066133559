/* Module: S */
.top-search-box {
    position: absolute;
    top: 32%;
    left: 50%;
    z-index: 7;
    // width: pxToRem(314);
    margin: 0 auto;
    margin-left: pxToRem(-182);
    text-align: center;
    &.down {
        top: calc(32% + 80px);
    }

    &__search {
        display: flex;
        justify-content: center;
    }
    &__wrapper {
        position: relative;
        // width: 306px;
    }

    &__header {
        color: $alt-font-color;
        font-size: pxToRem(24);
        font-weight: 300;
        margin-bottom: pxToRem(10);
    }
    &__input {
        border: 1px solid $base-bg-color;
        border-radius: pxToRem(50);
        padding: 0 pxToRem(15);
        padding-left: pxToRem(25);
        height: pxToRem(36);
        width: 306px;
        font-size: pxToRem(13);
        line-height: pxToRem(38);
        background: $alt-font-color;
        color: $color-primary;
        &:focus {
            background-color: $base-bg-color;
            outline: none;
            .search__btn {
                background: url('{{file_dest}}/search-glass.png') center center no-repeat;
            }
        }

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $color-primary;
            opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $color-primary;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
            color: $color-primary;
        }
    }
    &__btn {
        position: absolute;
        right: pxToRem(10);
        top: pxToRem(4);
        z-index: 1;
        background: none;
        border: none;
        width: pxToRem(30);
        height: pxToRem(30);
        background: url('{{file_dest}}/search-glass.png') center center no-repeat;
    }
}
.top-search-box__wrapper.twitter-typeahead {
    width: 306px;
}
.top-search-box__wrapper .twitter-typeahead .tt-menu {
    margin: pxToRem(12) 0;
    padding: pxToRem(8) 0;
    background-color: $alt-font-color;
    -webkit-border-radius: pxToRem(27);
    -moz-border-radius: pxToRem(27);
    border-radius: pxToRem(27);
    text-align: left;
    box-shadow: 0px 3px 10px #D6D6D6;
}
.tt-input::-ms-clear {
    display: none;
}

input::-ms-clear {
    display: none;
    height: 0;
    width: 0;
}

.top-search-box__wrapper .twitter-typeahead .tt-suggestion {
    padding: pxToRem(3) pxToRem(20);
    font-size: pxToRem(13);
    line-height: pxToRem(24);
    width: 306px;
    color: $color-primary;
}
.twitter-typeahead {
    width: 100%;
}
