/* Module: S */
.home-about {
    padding: 6% $mobile-side-padding;

    &__wrapper {
        max-width: 315px;
        margin: 0 auto;
    }

    &__head {
        font-size: pxToEm(24);
        font-weight: 300;
        margin: 0;
        padding-bottom: 0;
        color: $color-primary;
    }

    &__title {
        color: $color-secondary;
        font-size: pxToEm(14);
        font-weight: 300;
    }

    &__text {
        font-size: pxToEm(13);
        padding-bottom: 20px;
        color: $color-primary;
        font-weight: 500;
        line-height: pxToRem(20);
    }
}