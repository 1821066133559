/* Module: S */
@media (min-width: 1024px) {
    .header-menu {
        float: left;
        display: block;
    }
    .mobile-menu__button {
        display: none;
    }
    
    .mobile-menu__close {
        display: none;
    }
    .header-menu {
        &__list {
            @include sq-list-reset;
            margin-top: 0;
            // overflow: hidden
        }
    
        &__item {
            display: inline-block;
            position: relative;
            padding: pxToEm(20);
            @include sq-transition(background-color .3s ease-in-out);
    
            &:hover, &.focusin {
                background-color: rgba(0,54,95,0.7);
                > .header-menu__sub, > .header-menu__third {
                    visibility: visible;
                    opacity: 1;
                    z-index: 1;
                    transition-delay: 0s, 0s, 0.3s;
                }
    
                > .header-menu__sub {
                    @include sq-transform(translateY(0%));
                }
    
                > .header-menu__third {
                    @include sq-transform(translateX(0%));
                }
    
                .header-menu__link {
                    color: $alt-font-color;
                }
            }    
        }
    
        &__link {
            font-weight: bold;
            color: $alt-font-color;
            font-size: pxToEm(16);
            text-decoration: none;
        }
    
        &__sub, &__third {
            display: block;
            visibility: hidden;
            opacity: 0;
            min-width: 312px;
            position: absolute;
            // width: 100%;
            z-index: -1;
            @include sq-transition(all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s);
    
            .header-menu__item {
                padding: pxToEm(10) pxToEm(20);
                display: block;
                &:hover {
                    background-color: $base-bg-color;
                    > .header-menu__link {
                        color: $color-primary;
                    }
                }
            }
    
            .header-menu__link {
                display: block;
            }
        }
    
        &__sub {
            @include sq-transform(translateY(-2em));
            top: 100%;
            left: 0;
            background-color: rgba(0,54,95,0.7);
            
        }
    
        &__third {
            @include sq-transform(translateX(-2em));
            top: 0;
            right: 100%;
            background-color: rgba(173,70,153,0.6);
        }
    }
}

@media (min-width: 1300px) {
    .header-menu{
        &__third{
            left: 100%;
            right: 0;
        }

        &__item{
            // 3rd lvl to the left
            &:nth-last-child(2){
                .header-menu__list-wrapper > .header-menu__list > &{
                    .header-menu__third{
                        right:100%;
                        left: auto;
                    }
                }
            }

            // 2nd and 3rd lvl to the left
            &:last-child{
                .header-menu__list-wrapper > .header-menu__list > &{
                    .header-menu__sub{
                        right: 0;
                        left:auto;
                    }
                    .header-menu__third{
                        right:100%;
                        left: auto;
                    }
                }
            }
        }
    }
}