/* Module: S */
.image-box {
    min-height: pxToEm(760);

    &--right {
        &:after {
            top: -1000px;
            left: 25%;
        }

        .image-box__wrapper {
            float: right;
            margin-right: 15%;
        }
    }

    &--left {
        &:after {
            top: -1000px;
            right: 25%;
        }

        .image-box__wrapper {
            float: left;
            margin-left: 15%;
            text-align: left;
        }
    }

    &__wrapper {
        max-width: 510px;
        padding: 0;
        margin-top: 7%;
    }

    &__head {
        font-size: pxToEm(45);
        padding-bottom: 10px;
    }

    &__title {
        font-size: pxToEm(20);
        line-height: pxToRem(33);
    }

    &__text {
        font-size: pxToEm(18);
        padding-bottom: 20px;
        line-height: pxToRem(33);
    }
}