.container {
    max-width: 886px;
}
/*
--------------------
Modules
--------------------
*/

/* Buttons */
.button {
    font-size: pxToEm(20);
    padding: 0.6em 1.5em;
    font-weight: 700;
}

.button--dark {
    font-size: pxToEm(20);
    padding: 0.6em 1.5em;
    font-weight: 700;
}