/* Module: S */
.search {
    display: none;
    margin-bottom: 0;
}
.search.mobile-active {
    display: none;
    background-color: $color-primary;
    padding-top: 35px;
    padding-bottom: 35px;
    &.show {
        display: block;
    }
    & .search {
        &__wrapper {
            position: relative;
            // margin-left: 30px;
        }
        &__btn {
            display: none;
            position: absolute;
            right: 8px;
            top: 4px;
            z-index: 1;
            background: none;
            border: none;
            width: 30px;
            height: 30px;
            background: url('{{file_dest}}/search.svg') center center no-repeat;
            &:hover {
                background: url('{{file_dest}}/search-pink.svg') center center no-repeat;
            }
            &.focused {
                background: url('{{file_dest}}/search-pink.svg') center center no-repeat;
            }
        }

        &__input {
            border: 1px solid $base-bg-color;
            border-radius: 50px;
            padding: 0 15px;
            margin-left: 30px;
            height: 38px;
            line-height: 38px;
            background: none;
            width: 240px;
            color: $alt-font-color;
            &:focus {
                background-color: $base-bg-color !important;
                color: $color-primary;
                outline: none;
            }

            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $alt-font-color;
                opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: $alt-font-color;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
                color: $alt-font-color;
            }
        }
    }

}

.search__wrapper .twitter-typeahead .tt-menu {
    margin: 0;
    // padding: pxToRem(8) 0;
    background-color: $color-primary;
    text-align: left;
    padding-left: 30px;
    width: calc(100% - 30px);
    margin-left:30px;
    padding-left: 0px;
}
.search__wrapper .twitter-typeahead .tt-suggestion {
    padding: pxToRem(3) pxToRem(20);
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    color: $alt-font-color;
}

.mobile-search__button {
    padding: 0;
    text-decoration: none;
    height: 52px;
    line-height: 52px;
    width: 52px;
    margin-top: 6px;
    display: inline-block;
    text-align: center;
    float: right;
    @include rwd-to($rwd-tablet-small){
        padding: 0;
        margin-right: 40px;
        float: none;
    }
}

.mobile-search__close {
    position: absolute;
    right: 30px;
    top: 10px;
}