/* Module: S */
.find-job {
    padding-bottom: 6%;

    & .section-label {
        margin-top: pxToEm(20);
    }

    &__list {
        overflow: hidden;
    }

    &__more-wrapper {
        text-align: center;
        padding-top: 2%;
        position: relative;
    }
    &__item {
        background-color: #F2F4F7;
        float: left;
        width: 100%;
        margin: 0 pxToEm(10) pxToEm(20);
        &:nth-child(4n + 1) {
            clear: left;
        }
    }
    &-item {
        &__wrapper {
            padding: pxToEm(20) pxToEm(10) pxToEm(25) pxToEm(20);
            @include sq-transition(background-color .3s ease-in-out);
        }
        &__link {
            text-decoration: none;
            color: $color-primary;
        }
        &__title {
            margin: 0 0 pxToEm(5);
            font-size: pxToEm(24);
            font-weight: bold;
            color: $color-secondary;
            padding-right: pxToEm(80);
        }
        &__date {
            position: absolute;
            top: 0;
            right: 0;
            margin-top: pxToEm(30);
            margin-right: pxToEm(40);
            font-size: pxToEm(14);
            font-weight: 100;
            opacity: 0.5;
            font-style: italic;
        }
        &__text {
            font-size: pxToEm(13);
            margin-bottom: pxToEm(20);
            font-weight: 500;
            padding-right: pxToEm(10);
        }
        &__read-more {
            font-size: pxToEm(14);
            padding-right: pxToEm(30);
            font-weight: bold;
            color: $color-secondary;
            // background: url('{{file_dest}}/arrow.svg') no-repeat right center;
        }
    }
}