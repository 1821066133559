/* Module: S */
h2 {
    font-size: pxToRem(45);
    padding-bottom: pxToRem(15);
}

h3 {
    font-size: pxToRem(31);
    padding-bottom: pxToRem(15);
}

h4 {
    font-size: pxToRem(27);
    padding-bottom: pxToRem(15);
}

h5 {
    font-size: pxToRem(24);
    padding-bottom: pxToRem(15);
}

h6 {
    font-size: pxToRem(18);
    padding-bottom: pxToRem(15);
}

.text-content {
    &__wrapper {
        max-width: pxToRem(486);
    }
    &__title {
        font-size: pxToRem(20);
        font-weight: 300;
        padding-bottom: pxToRem(15);
    }
    p {
        font-size: pxToRem(18);
        font-weight: 300;
        //padding-bottom: pxToRem(15);
        line-height: pxToRem(33);
    }

    ul {
        font-size: pxToRem(18);
        font-weight: 300;
        line-height: pxToEm(33);
    }

    li {
        padding-bottom: 0;
    }
}

blockquote {
    font-size: pxToRem(30);
    margin-top: pxToRem(80);
    line-height: pxToRem(43);
    padding-left: pxToRem(90);
    &:before {
        font-size: pxToRem(450);
        margin-top: pxToRem(-110);
    }
}