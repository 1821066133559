/* Module: S */
.footer {
    background-color: $color-primary;
    color: $alt-font-color;

    &-menu {
        overflow: hidden;

        &__wrapper{
            border-top: 1px solid $alt-font-color;
        }

        &__info {
            font-size: pxToEm(14);
        }

        &__info, &__items {
            width: 100%;
            float: left;
            padding: 5% 0;
        }

        &__list {
            font-size: pxToEm(16);
        }

        &__contact,
        &__list {
            float: left;
            @include sq-list-reset;
            padding-right: 5%;
        }

        &__contact {
            width: 100%;
            margin-bottom: 20px;
            font-size: pxToEm(14);
        }

        &__list{
            width: 50%;
        }

        &__item {
            padding-left: 0;    
            font-size: pxToEm(14);
            line-height: 1.5;
        }

        &__phone, &__mail, &__text {
            display: block;
            margin-bottom: pxToEm(20);
        }

        &__mail, &__newsletter {
            font-weight: bold;
        }

        &__item {
            display: block;
            margin-bottom: pxToEm(10);
        }

        &__link {
            color: $alt-font-color;
            text-decoration: none;
            &:hover {
                color: $alt-font-color;
                text-decoration: underline;
            }
        }
    }

    &-info {
        background-color: $color-secondary;
        overflow: hidden;
        padding: 1.5% 0 1%;
        &__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &-copyright, &-social {
        width: 100%;
        float: left;
    }

    &-copyright {
        color: $alt-font-color;
        font-size: pxToEm(14);
        margin-top: 20px;
        margin-bottom: 30px;
        text-align: center;
    }

    &-social {
        text-align: right;
        margin-bottom: 10px;

        &__list {
            @include sq-list-reset;
            display: flex;
            justify-content: center;
        }

        &__item {
            display: inline-block;
            margin-right: pxToEm(25);
            margin-left: 0;
            padding-left: 0;
        }

        &__link {
            @include sq-transition(opacity .3s ease-in-out);

            &:hover {
                @include sq-opacity(60);
            }
        }
    }
}