/* Module: S */
.our-people {
    &__wrapper {
        max-width: pxToRem(1133);
    }
    &__head {
        padding-bottom: pxToEm(8);
    }
}

.our-people-item {
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 0;
    &__image {
        width: 30%;
        height: pxToRem(324);
        margin-right: pxToRem(30);
    }
    &__text-section {
        width: 70%;
    }

    &__button {
        left: 50%;
        transform: translateX(-50%);
        padding-left: 20px;
    }

    &__accordion {
        &:before {
            content: '';
            position: absolute;
            bottom: pxToEm(-11);
            left: 0;
            width: 100%;
            height: pxToEm(1);
            background-color: $color-secondary;
            z-index: 1;
            display: none;
        }
        &.active:before {
            display: block;
        }
    }
    &__social {
        position: absolute;
        top: 0;
        right: 0;
        text-align: right;
        display: flex;
        margin-top: 0;
    }
}