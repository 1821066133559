/* Module: S */
.page-subtitle {
    &__wrapper {
        padding: 0;
        width: 486px;
    }

    &__head {
        font-size: pxToEm(45);
        padding-bottom: 10px;
    }

    &__title {
        font-size: pxToEm(20);
    }
    &__text {
        font-size: pxToEm(18);
        font-weight: 300;
        line-height: pxToRem(33);
    }
}
