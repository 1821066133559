/* Module: S */
.search {
    &__wrapper {
        position: relative;
        margin-top: pxToEm(10);
    }
    &__btn {
        position: absolute;
        right: 8px;
        top: 4px;
        z-index: 1;
        background: none;
        border: none;
        width: 30px;
        height: 30px;
        background: url('{{file_dest}}/search.svg') center center no-repeat;
        &:hover {
            background: url('{{file_dest}}/search-pink.svg') center center no-repeat;
        }
        &.pink {
            background: url('{{file_dest}}/search-pink.svg') center center no-repeat;
        }
    }

    &__input {
        border: 1px solid $base-bg-color;
        border-radius: 50px;
        padding: 0 15px;
        height: 38px;
        line-height: 38px;
        background: none;
        width: 260px;
        color: $alt-font-color;
        &:focus {
            background-color: $base-bg-color !important;
            color: $color-primary;
            outline: none;
            .search__btn {
                background: url('{{file_dest}}/search-pink.svg') center center no-repeat;
            }
        }

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $alt-font-color;
            opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $alt-font-color;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
            color: $alt-font-color;
        }
    }
}


@media (min-width: 1300px) {
    .mobile-search__button {
        display: none;
    }
    .search {
        display: block;
        float: right;
        padding-left: pxToEm(60);
        margin-bottom: 15px;
    }
    .search__wrapper .twitter-typeahead .tt-menu {
        width: pxToRem(260);
        margin: pxToRem(12) 0;
        padding: pxToRem(8) 0;
        background-color: $alt-font-color;
        -webkit-border-radius: pxToRem(27);
        -moz-border-radius: pxToRem(27);
        border-radius: pxToRem(27);
        text-align: left;
    }
    .search__wrapper .twitter-typeahead .tt-suggestion {
        padding: pxToRem(3) pxToRem(20);
        font-size: pxToRem(16);
        line-height: pxToRem(24);
        color: $color-primary;
    }
}