/* Module: S */
.project-services {
    padding: 6% 40px;
    padding-top: 0;
}

.service {
    &__list {
        padding: 0;
        display: block;
    }

    &__item {
        width: calc(33% - 40px);
        margin: 0 20px 3%;
        &:hover {
            .service__wrapper {
                background-color: $color-primary; opacity: 1;
                @include sq-transition(opacity .5s ease-in-out);
            }
            .service__dots {
                display: block;
            }
            .service__text {
                margin-bottom: pxToEm(30);
            }
        }
    }

    &__wrapper {
        opacity: 0.8;
    }

    &__title {
        margin-top: pxToEm(35);
        margin-bottom: pxToEm(10);
        font-size: pxToEm(24);
    }

    &__text {
        font-size: pxToEm(16);
        margin-bottom: pxToEm(50);
    }
    &__dots {
        display: none;
    }
}