/* Module: S */
.home-news {
    padding: 0 $mobile-side-padding;
    &__wrapper {
        max-width: 315px;
        margin: 0 auto;
    }

    &--no-heading{
        margin-top: pxToRem(20);
    }
}

.news {
    &__list {
        overflow: hidden;
    }

    &__item {
        float: left;
        width: 100%;
        margin: 0 0 3%;
    }

    &__link {
        text-decoration: none;
        color: $color-primary;
    }

    &__image {
        display: block;
        margin-bottom: 0;
    }

    &__wrapper {
        padding: pxToEm(20) pxToEm(20) pxToEm(40) pxToEm(20);
        @include sq-transition(background-color .3s ease-in-out);
    }

    &__title {
        margin: 0 0 pxToEm(5);
        font-size: pxToEm(18);
        font-weight: normal;
        color: $color-secondary;
        word-wrap: break-word;
    }

    &__date {
        margin-bottom: pxToEm(15);
        font-size: pxToEm(13);
        font-weight: 700;
    }

    &__text {
        font-size: pxToEm(13);
        margin-bottom: pxToEm(10);
        font-weight: 500;
    }

    &__more-wrapper {
        margin-top: 20px;
        margin-bottom: 40px;
        text-align: center;
        padding-top: 2%;
    }

    &__read-more {
        font-size: pxToEm(14);
        padding-right: pxToEm(30);
        font-weight: bold;
        color: $color-secondary;
        // background: url('{{file_dest}}/arrow.svg') no-repeat right center;
    }
    &__more-btn.loading{
        position:relative;
        color:transparent;
        &::after{
            position:absolute;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
            content:'';
            background-image:url('{{file_dest}}/loader.png');
            background-position: center;
            background-size: contain;
            width:25px;
            height:25px;
            animation:loader-spin 2s linear infinite;
        }
    }
    @keyframes loader-spin{
        0%{
            transform:translate(-50%,-50%) rotate(0deg)
        }
        100%{
            transform:translate(-50%,-50%) rotate(360deg)
        }
    }
}

.home-news .section-label {
    padding-top: 6%;
    color: $base-font-color;
}

.news__image-box {
    height: 293px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.news__label {
    position: absolute;
    left: 20px;
    bottom: 12px;
    color: $alt-font-color;
    font-size: 14px;
    font-weight: 700;
}