/* Module: S */

.subpage-banner {
    height: pxToEm(291);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-start;
    
    &__title {
        font-size: pxToRem(45);
    }
}