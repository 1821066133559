/* Module: S */
.footer {
    &-menu {
        &__info, &__items {
            width: 100%;
            padding: 3% 0;
        }

        &__contact, &__list{
            width: 33.3333%;
        }
        
        &__contact {
            margin-bottom: 0;
        }
    }

    &-info {
        &__wrapper {
            flex-direction: row;
        }
    }

    &-copyright, &-social {
        width: 50%;
    }

    &-copyright {
        text-align: left;
        margin: 0;
    }

    &-social {
        margin-bottom: 0;

        &__list {
            display: block;
        }
        &__item {
            margin-left: pxToEm(25);
            margin-right: 0;
        }
    }
}