/* Module: S */
.subpage-banner--career {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: pxToEm(291);
    position: relative;display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-start;

    &:before {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+75 */
        background: -moz-linear-gradient(left,  rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 75%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 75%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 75%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        height: 100%;
        width: 100%;
    }
}

.banner__career-links {
    color: $alt-font-color;
    font-weight: 600;
    font-size: pxToEm(14);
    z-index: 3;
    margin-bottom: 20px;
}

.banner__career-link {
    background: url('{{file_dest}}/arrow-down-mobile.png') no-repeat right center;
    padding-right: pxToEm(25);
    margin-right: pxToEm(15);
    color: $alt-font-color;
    text-decoration: none;
    cursor: pointer;
}

.banner__contact-links {
    color: $alt-font-color;
    font-weight: 600;
    font-size: pxToEm(14);
    z-index: 3;
    margin-bottom: 20px;
}

.banner__contact-link {
    background: url('{{file_dest}}/arrow-down-mobile.png') no-repeat right center;
    padding-right: pxToEm(25);
    margin-right: pxToEm(15);
    color: $alt-font-color;
    text-decoration: none;
    cursor: pointer;
}