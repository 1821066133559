/* Module: S */
.find-job {
    & .section-label {
        text-align: center;
        margin-top: pxToEm(30);
        margin-bottom: pxToEm(10);
    }
    &__item {
        width: calc(50% - 20px);
        margin: 0 pxToEm(10) 3%;
    }
    &-item {
        &__link {
            &:hover {
                color: $alt-font-color;
                .find-job-item__wrapper {
                    background-color: $color-secondary;
                    @include sq-transition(background-color .5s ease-in-out);
                }
                .find-job-item__title {
                    color: $alt-font-color;
                }
                .find-job-item__read-more {
                    color: $alt-font-color;
                    // background: url('{{file_dest}}/arrow-white.svg') no-repeat right center;
                }
            }
        }
        &__title {
            font-weight: normal;
        }
        &__date {
            margin-top: pxToEm(20);
            margin-right: pxToEm(30);
        }
        &__text {
            font-size: pxToEm(16);
            font-weight: 300;
        }
        &__read-more {
            font-size: pxToEm(18);
            padding-right: pxToEm(20);
        }
    }
}