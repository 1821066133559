/**
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * updated: {{date}}
 *
 * Table of Contents
 *
 * - Reset
 * - Utilities
 * - General
 * - Content
 * - Modules
    {{toc}}
 */

// These imports have no CSS output, but provide variables, mixins and helpers to aid in construction of
// a design cutup.
@import "boilerplate.scss";

/*
--------------------
Reset (normalize.css)
--------------------
*/

@import "normalize.scss";

// This import disabled by default due to rendering issues, particularly with IE where some text doesn't render at all
//@import "normalize-opentype.scss";

// Box size all the things
// https://squizmap.squiz.net/imp-bp/6400
// http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
// == Start box-sizing reset ==
*, *:before, *:after {
    -webkit-box-sizing: inherit;
       -moz-box-sizing: inherit;
            box-sizing: inherit;
}
html {
    @include sq-border-box;
}
// == End box-sizing reset ==

/*
--------------------
Utilities
--------------------
*/

@import "utilities.scss";

/*
--------------------
General
--------------------
*/

// Taken from HTML 5 BP
::-moz-selection {
    background: $content-selection-highlight;
    text-shadow: none;
}

::selection {
    background: $content-selection-highlight;
    text-shadow: none;
}

html,
body {
    height: 100%;
}
body {
    overflow: hidden;
    overflow-y: scroll;
    @include set-base-font-size(font-size, $base-font-pixel);
    line-height: 1.5;
    padding: 0;
    margin: 0;
    font-family: $base-font-family;
}

img {
    max-width: 100%;
}

// Reset fonts for relevant elements (taken from bootstrap)
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

a {
    color: $color-primary;
    @include sq-transition(color .3s ease-in-out);

    &:hover {
        color: $color-secondary;
    }
}

a:focus {
    outline: 5px auto rgb(94, 158, 214);
}

button:focus {
    outline: none;
}

.container {
    max-width: 515px;
    padding: 0 30px;
    margin: 0 auto;
}

.section-label {
    text-align: left;
    font-weight: 300;
    font-size: pxToEm(24);
    padding-bottom: pxToRem(10);
}

.global-overlay {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    background: rgba(0, 0, 0, .3);
    &.active {
        display: block;
    }
}

.tt-menu {
    overflow: hidden;
}

// IE10 Metro responsive
// Required for Windows 8 Metro split-screen snapping with IE10
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport{
    width: device-width;
}

// Print style that shouldn't belong to print.scss to avoid the css beautifier from killing
// the @ syntax inside media queries
@page {
    margin: 0.5cm;
}

/*
--------------------
Content
--------------------
*/

// You can wrap the content styles in a class by un-commenting the following lines
// to nest the content.scss styles inside .content
//.content {
    @import "content.scss";
//}

/*
--------------------
Global Modules
--------------------
*/
@keyframes fade-in {
    0%{
        opacity:0
    }
    100%{
        opacity:1,
    }
}

html{
    overflow-y: hidden;
}
.modal-open{
    overflow-x:hidden;
    position:relative;
    height:100%;
    &::before{
        animation: fade-in 0.3s ease-in;
        background-color: rgba(0, 0, 0, .4);
        position:absolute;
        top:0%;
        left:0;
        height:100%;
        width:100%;
        content:'';
        backdrop-filter: blur(2px);
    }
}
.read-more{
    display: table;
    line-height: 1em;
    &:after{
        content : '';
        display: table-cell;
        vertical-align: middle;
        width: pxToEm(32);
        height: pxToEm(16);
        background-image: url('{{file_dest}}/arrow.svg');
        background-position: center;
        background-size: 50% auto;
        background-repeat: no-repeat;

        a:hover &{
            background-image: url('{{file_dest}}/arrow-white.svg');
        }
    }
}

/*
--------------------
Modules
--------------------
*/



/*
--------------------
JS disabled
--------------------
*/

.no-js *[data-aos="zoom-in"], .no-js *[data-aos="zoom"], .no-js *[data-aos="fade-up"], .no-js *[data-aos="fade-left"], .no-js *[data-aos="fade-right"], .no-js [data-aos^=fade][data-aos^=fade] {
    transform: none;
}

.no-js *[data-aos="zoom-in"], .no-js *[data-aos="zoom"], .no-js *[data-aos="fade-up"], .no-js *[data-aos="fade-left"], .no-js *[data-aos="fade-right"], .no-js [data-aos^=fade][data-aos^=fade] {
    @include sq-opacity(100);
}