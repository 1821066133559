/* Module: S */
.image-box {
    min-height: pxToEm(740);
    background-repeat: no-repeat;

    &--right {
        &:after {
            top: 250px;
            right: -500px;
        }
    }

    &--left {
        &:after {
            top: 250px;
            right: -600px;
        }
    }
}