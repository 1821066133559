/* Module: S */
.contact-page-form {
    padding: 0 0 12%;
    margin-bottom: 80px;

    &__wrapper {
        width: pxToEm(315);
        margin: 0 auto;
    }
}
.form-inputs {
    position: relative;
}
.form-col {
    &--left {
        width: 100%;
        float: left;
    }
    &--right {
        width: 100%;
        float: left;
    }
    &--full {
        width: 100%;
    }
}
.formular {
    &__input-text {
        margin-top: pxToRem(10);
        margin-bottom: pxToRem(15);
        width: 100%;
        height: pxToRem(36);
        border: 1px solid $color-primary;
        border-radius: pxToRem(27);
        padding-left: pxToRem(20);
        font-weight: 700;
        color: $color-primary;
        font-size: 13px;
        &::placeholder {
            color: $color-primary;
            font-weight: 300;
        }
        &:active {
            border: 2px solid $color-secondary;
        }
        &:focus, &:hover {
            border: 2px solid $color-secondary;
            outline: none;
        }
    }
    &__textarea {
        margin-top: pxToRem(10);
        margin-bottom: pxToRem(15);
        width: 100%;
        border: 1px solid $color-primary;
        border-radius: pxToRem(27);
        padding-left: pxToRem(20);
        padding-top: pxToRem(10);
        font-weight: 700;
        color: $color-primary;
        font-size: 13px;
        &::placeholder {
            color: $color-primary;
            font-weight: 300;
        }
        &:active {
            border: 2px solid $color-secondary;
        }
        &:focus, &:hover {
            border: 2px solid $color-secondary;
            outline: none;
        }
    }
    &__submit {
        margin-top: pxToRem(10);
        position: absolute;
        right:0;
    }
}

input.parsley-error {
    border: 1px solid rgb(238, 70, 70);
}
ul.parsley-errors-list {
    margin: 1px 0 3px;
    margin-top: -10px;
    padding: 0;
    list-style: none;
    font-size: 0.9em;
    line-height: 0.9em;
    opacity: 0;
    color: rgb(238, 70, 70);
    transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    -moz-transition: all .3s ease-in;
    -webkit-transition: all .3s ease-in;
}
.parsley-errors-list.filled {
    opacity: 1;
}