/* Module: S */
.video-content {
    margin-bottom: 40px;
    &__wrapper {
        max-width: pxToRem(1133);
    }
}

.video-content.container {
    max-width: 486px;
}