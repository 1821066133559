/* Module: S */
.locations-box {
    &__wrapper {
        max-width: pxToRem(1400);
    }
    &__head {
        font-size: pxToRem(35);
        margin-bottom: pxToRem(-30);
    }
    &__title {
        font-size: pxToRem(55);
    }
    &__text {
        font-size: pxToRem(20);
    }
}
.locations-list {
    display: block;
}
.location {
    &__item {
        float: left;
        text-align: left;
        width: calc(25% - 20px);
        margin: 0 10px 7%;
        &:nth-child(2n + 1) {
            clear: none;
        }
        &:nth-child(4n + 1) {
            clear: left;
        }
    }
    &__title {
        font-size: pxToRem(45);
    }
    &__address {
        font-size: pxToRem(20);
    }
    &__phone {
        font-size: pxToRem(20);
    }
    &__person {
        font-size: pxToRem(20);
    }
    &__email {
        font-size: pxToRem(20);
    }
}