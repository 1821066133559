// Base font
$base-font-scale: 1;  // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-family: 'Montserrat', sans-serif;
$base-font-color: #003462;
$base-bg-color: #ffffff;

$alt-font-color: #ffffff;

$color-primary: #00365F;
$color-secondary: #AD4699;
$color-secondary-light: #C967B6;
$color-btn-text: #ffffff;

$rwd-desktop-max: 1920;
$rwd-tablet: 1320;
$rwd-desktop:1200;
$rwd-tablet-small: 1024;
$rwd-tablet-xs:959;
$rwd-mobile: 768;
$rwd-mobile-small: 480;

// Selection highlighting
$content-selection-highlight: #b3d4fc;

$mobile-side-padding: 30px;