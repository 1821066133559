/* Module: S */
.find-job {
    &__container {
        min-height: pxToEm(368);
        text-align: center;
        width: 100vw;
        margin-left: calc((100vw - 100%) / 2 * -1);
    }
    &__btn {
        margin-bottom: 50px;
        margin-left: 15px;
        margin-right: 15px;
    }

    &__wrapper {
        max-width: pxToEm(315);
        margin: 0 auto;
    }

    &__head {
        font-size: pxToEm(24);
        font-weight: 300;
        margin: 0;
        padding-top: pxToEm(30);
        padding-bottom: pxToEm(5);
        color: $color-primary;
    }

    &__text {
        text-align: left;
        color: $color-primary;
        line-height: pxToEm(22);
        font-size: 13px;
        padding-bottom: pxToEm(25);
    }
    &__inputs {
        padding-bottom: pxToEm(30);
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        -webkit-align-items: center;
    }
    &__input {
        width: 254px;
        margin-bottom: pxToEm(10);
        position: relative;
    }
    &__input-item {
        border: 1px solid $alt-font-color;
        border-radius: 50px;
        height: 38px;
        line-height: 38px;
        background: $alt-font-color;
        color: #00365F;
        width: 254px;
        padding-left: 20px;
        font-size: 14px;
        cursor: pointer;
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $base-font-color;
            opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $base-font-color;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
            color: $base-font-color;
        }
    }
    &__button {
        &--search {
            background: url('{{file_dest}}/search-blue.png') center center no-repeat;
            position: absolute;
            right: 8px;
            top: 4px;
            z-index: 1;
            border: none;
            width: 30px;
            height: 30px;
        }
        &--location {
            background: url('{{file_dest}}/arrow-down-blue.png') center center no-repeat;
            position: absolute;
            right: 8px;
            top: 4px;
            z-index: 1;
            border: none;
            width: 30px;
            height: 30px;
        }
    }
}
.find-job__input.twitter-typeahead {
    width: 254px;
}
.find-job__input .twitter-typeahead .tt-menu {
    width: 254px;
    margin: pxToRem(12) 0;
    padding: pxToRem(8) 0;
    background-color: $alt-font-color;
    -webkit-border-radius: pxToRem(27);
    -moz-border-radius: pxToRem(27);
    border-radius: pxToRem(27);
    text-align: left;
}
.find-job__input .twitter-typeahead .tt-suggestion {
    padding: pxToRem(3) pxToRem(20);
    font-size: pxToRem(14);
    line-height: pxToRem(24);
    color: $color-primary;
    width: 254px;
    z-index: 2;
}

.find-job__location-select {
    display: block;
    border: 1px solid #ffffff;
    border-radius: 50px;
    height: 38px;
    line-height: 38px;
    color: #00365F;
    width: 254px;
    padding-left: 20px;
    font-size: 14px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url('{{file_dest}}/arrow-down-blue.png') 95% center no-repeat;
    background-color: #ffffff;
    cursor: pointer;
}

.find-job__location-select::-ms-expand {
    display: none;
}
