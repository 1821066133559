/* Module: S */
.image-box {
    min-height: pxToEm(640);
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
    display: flex;

    &--right {
        background-position: left center;

        &:after {
            content: '';
            width: 2000px;
            height: 2000px;
            border-radius: 100%;
            position: absolute;
            top: 220px;
            z-index: 1;
            right: -500px;
        }
        &.pink {
            &:after {
                background-color: $color-secondary;
            }
        }
        &.blue {
            &:after {
                background-color: $color-primary;
            }
        }
    }

    &--left {
        background-position: right center;

        &:after {
            content: '';
            width: 2000px;
            height: 2000px;
            border-radius: 100%;
            position: absolute;
            top: 220px;
            z-index: 1;
            right: -1000px;
        }
        &.pink {
            &:after {
                background-color: $color-secondary;
            }
        }
        &.blue {
            &:after {
                background-color: $color-primary;
            }
        }

        .image-box__wrapper {
            text-align: right;
        }
    }

    &__wrapper {
        margin: 0 auto;
        margin-top: 300px;
        max-width: 375px;
        position: relative;
        z-index: 2;
        color: $alt-font-color;
        margin-bottom: 35px;
        padding: 0 $mobile-side-padding;
    }

    &__head {
        font-size: pxToEm(24);
        font-weight: 300;
        margin: 0;
        padding-bottom: 0;
    }

    &__title {
        font-size: pxToEm(14);
        font-weight: 300;
        line-height: pxToRem(20);
    }

    &__text {
        font-size: pxToEm(13);
        padding-bottom: 10px;
        font-weight: 300;
        line-height: pxToRem(20);
    }

    &__btn {
        &:hover {
            background-color: $base-bg-color;
            color: $color-primary;
        }
    }
}
.image-box.pink {
    & .image-box__btn.button {
        background-color: $color-primary;
        &:hover {
            background-color: $base-bg-color;
            color: $color-primary;
        }
    }
}

.image-box.blue {
    & .image-box__btn.button {
        background-color: $color-secondary;
        &:hover {
            &:hover {
                background-color: $base-bg-color;
                color: $color-secondary;
            }            
        }
    }
}