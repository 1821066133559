/* Module: S */
.subpage-banner--career {
    height: pxToEm(500);
    position: relative;
    &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;
        height: 100%;
        width: 100%;
    }
    &.pink {
        &:after {
            background: url('{{file_dest}}/top-shape-pink.svg') right bottom no-repeat;
        }
    }
    &.blue {
        &:after {
            background: url('{{file_dest}}/top-shape.svg') right bottom no-repeat;
        }
    }
}

.banner__career-links {
    font-size: pxToEm(20);
    margin-bottom: 30px;
}

.banner__career-link {
    background: url('{{file_dest}}/arrow-down.png') no-repeat right center;
    margin-right: pxToEm(60);
}

.banner__contact-links {
    font-size: pxToEm(20);
    margin-bottom: 30px;
}

.banner__contact-link {
    background: url('{{file_dest}}/arrow-down.png') no-repeat right center;
    margin-right: pxToEm(60);
}