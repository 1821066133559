/* Module: S */
.main-banner {
    min-height: pxToEm(745);

    &__wrapper {
        padding-top: pxToEm(295);
    }

    &:after {
        background-position: top right;
    }
    &.pink {
        &:after {
            background-image: url('{{file_dest}}/top-shape-pink.svg');
        }
    }
    &.blue {
        &:after {
            background-image: url('{{file_dest}}/top-shape.svg');
        }
    }
}

@media (min-width: 1320px) {
    .main-banner {
        &__title {
            width: 50%;
            font-size: pxToEm(90);
        }
    }
}