/* Module: S */
.contact-page-form {
    padding-bottom: 12%;
    margin-bottom: 60px;

    &__wrapper {
        width: pxToEm(486);
    }
}
.form-col {
    &--left {
        width: 50%;
        padding-right: pxToRem(20);
    }
    &--right {
        width: 50%;
        padding-left: pxToRem(20);
    }
}
.formular {
    &__input-text {
        height: pxToRem(53);
        padding-left: pxToRem(30);
        font-size: 18px;
    }
    &__textarea {
        padding-left: pxToRem(30);
        padding-top: pxToRem(20);
        font-size: 18px;
    }
}