/* Module: S */
.top-search-box {
    // width: pxToRem(700);
    margin-left: pxToRem(-350);
    &__wrapper {
        // width: pxToRem(700);
    }

    &__header {
        font-size: pxToRem(55);
    }
    &__input {
        height: pxToRem(53);
        width: 700px;
        font-size: pxToRem(18);
    }
    &__btn {
        right: pxToRem(14);
        top: pxToRem(11);
    }
}

.top-search-box__wrapper .twitter-typeahead .tt-suggestion {
    font-size: pxToRem(16);
    width: pxToRem(700);
}