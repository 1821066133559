/* Module: S */
.header-menu {
    display: none;
}
.header-menu.active {
    display: block;
    float: right;
    background-color: #003462;
    position: fixed;
    z-index: 10;
    right: 0;
    top: 0;
    overflow: hidden;
    height: 100vh;
    transition: 0.5s;
    min-width: 280px;
    padding-top: 90px;
    & .header-menu {
        &__list {
            @include sq-list-reset;
            // margin-top: 90px;
            // padding-right: 70px;
            overflow: hidden;

            &-wrapper{
                display: block;
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;
            }
        }

        &__item {
            display: block;
            position: relative;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);

            &.has-children > {
                .header-menu__link{
                    background: url('{{file_dest}}/arrow-down-white.png') no-repeat 90%;
                    &.clicked {
                        background: url('{{file_dest}}/arrow-top-white.png') no-repeat 90%;
                    }
                }
            }
        }

        &__link {
            font-weight: bold;
            color: $alt-font-color;
            font-size: pxToEm(16);
            text-decoration: none;
            padding: pxToEm(20) pxToEm(30);            
            display: block;
        }
        &__sub {
            display: none;
            // margin-top: 18px;
            // margin-bottom: -18px;
            // margin-top: 0;
            & .header-menu__link {
                font-weight: 300;
                font-size: pxToEm(16);
            }
            &.active-item{
                display: block;
            }
            .header-menu__item  {
                padding: pxToRem(10);
                padding-left: 0;
                padding-right: 0;
                font-weight: 300;
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            }
        }
        &__third {
            display: none;
            margin-top: 10px;
            margin-bottom: -10px;
            &.active-item{
                display: block;
            }
            .header-menu__item {
                padding: pxToRem(10);
                font-weight: 300;
                font-size: 14px;
                background-color: $base-bg-color;
                color: $color-secondary;
                text-align: center;
                padding-bottom: 0;
                &:last-child {
                    padding-bottom: pxToRem(20);
                }
            }
            .header-menu__link {
                color: $color-secondary;
            }
        }
    }

}

.mobile-menu__button {
    margin-top: 20px;
}

.mobile-menu__close {
    margin-bottom: 30px;
    position: absolute;
    top: 45px;
    right: 30px;
}