/* Module: S */
.locations-box {
    overflow: hidden;
    background-color: $color-primary;
    text-align: center;
    color: $alt-font-color;
    &__wrapper {
        padding: 2% 5% 0%;
        max-width: pxToRem(315);
    }
    &__head {
        color: $alt-font-color;
        font-size: pxToRem(24);
        font-weight: 300;
        margin-bottom: pxToRem(-20);
    }
    &__title {
        color: $alt-font-color;
        font-size: pxToRem(30);
        font-weight: 400;
        margin-bottom: 0;
    }
    &__text {
        font-size: pxToRem(14);
        font-weight: 300;
    }
    &__link {
        color: $alt-font-color;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
            color: $alt-font-color;
        }
    }
}
.locations-list {
    overflow: hidden;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.location {
    &__item {
        text-align: center;;
        margin: 0 10px 20%;
        &:nth-child(4n + 1) {
            clear: left;
        }
    }
    &__title {
        color: $alt-font-color;
        font-size: pxToRem(24);
    }
    &__address {
        font-weight: 300;
        font-size: pxToRem(14);
        margin-bottom: pxToRem(20);
        p {
            margin: 0;
        }
    }

    &__person {
        font-weight: 300;
        font-size: pxToRem(14);
    }

    &__phone {
        font-weight: 300;
        font-size: pxToRem(14);
        & a {
            color: $alt-font-color;
            text-decoration: none;
        }
    }
    &__email {
        font-weight: 300;
        font-size: pxToRem(14);
        &--link {
            color: $alt-font-color;
            text-decoration: none;
            &:hover {
                color: $alt-font-color;
                text-decoration: underline;
            }
        }
    }
}