/* Module: S */
.related-articles {
    background-color: #F2F4F7;

    & .section-label {
        padding-top: 20px;
        padding-left: 30px;
    }

    &__list {
        overflow: hidden;
        max-width: 1720px; 
        margin: 0 auto; 
        padding: 0 30px
    }
}