/* Module: S */
.search-results__content {
    padding: 6% 1%;
    margin-top: 20px;
}

.search-results__content.container {
    max-width: 315px;
}

.search-results__col-left {
    width: 100%;
}

.search-results__col-right {
    width: 100%;
}

.filters-dropdown {
    position: relative;
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 40px;
    }
}

.dropdown-heading {
    width: 100%;
    background-color: $base-bg-color;
    border: 1px solid $color-primary;
    text-align: left;
    padding: 10px 20px 12px 30px;
    color: $color-primary;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    background: url('{{file_dest}}/arrow-down-blue.png') no-repeat 92% 50%;
    outline-width: 0;
    &.active {
        color: $alt-font-color;
        background: url('{{file_dest}}/arrow-down-white.png') no-repeat 92% 50%;
        background-color: $color-primary;
    }
}

.dropdown-button {
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 1;
    background: none;
    border: none;
    width: 30px;
    height: 30px;
    background: url('{{file_dest}}/arrow-down-blue.png') center center no-repeat;
    &.active {
        background: url('{{file_dest}}/arrow-down-white.png') center center no-repeat;
    }
}

.dropdown-content {
    display: none;
    background-color: $color-primary;
    color: $alt-font-color;
    list-style: none;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 1em;
    padding-top: 1em;
    margin-top: 0;
}

.dropdown-item {
    padding: 1px 0;
    font-size: 13px;
    font-weight: 300;
    border-bottom: 1px solid white;
    label {
        text-transform: capitalize;
        cursor: pointer;
        display: block;
        padding: 12px 0 12px 45px;
        background: url('{{file_dest}}/filter-unselected.png') no-repeat 0 50%;
        outline-width: 0;
        word-break: break-all;
    }
    &:last-child {
        border-bottom: none;
    }
    &.active {
        label {
            background: url('{{file_dest}}/filter-selected.png') no-repeat 0 50%;
        }
    }
}

.show {
    display: block;
}

.search-tags {
    display: flex;
    padding-bottom: 30px;
    flex-wrap: wrap;
}

.tag-item {
    background-color: $base-bg-color;
    border: 1px solid $color-primary;
    text-align: left;
    padding: 10px 70px 12px 20px;
    color: $color-primary;
    font-size: 13px;
    margin-right: 20px;
    font-weight: 300;
    margin-bottom: 20px;
}

.tag-wrapper {
    position: relative;
}

.tag__close-button {
    position: absolute;
    right: 15px;
    top: 6px;
    z-index: 1;
    background: none;
    border: none;
    width: 1.875rem;
    height: 1.875rem;
    background: url('{{file_dest}}/delete-button.png') no-repeat 0 50%;
}

.jobs-list {
    margin-top: 0;
}
.results {
    //padding-top:30px;
}

.job-result {
    background-color: $base-bg-color;
    border: 1px solid $color-primary;
    padding: 20px;
    padding-bottom: 50px;
    margin-bottom: 30px;
    &__title {
        padding-bottom: 10px;
        font-size: 14px;
        font-weight: 700;
        margin-top: 10px;
    }
    &__location {
        color: $color-secondary;
        font-size: 13px;
        font-weight: 300;
        padding-left: pxToEm(30);
        background-image: url('{{file_dest}}/pin.png');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: auto 100%;
    }
    &__description {
        color: $color-primary;
        font-size: 14px;
        & p {
            margin-top: 10px;
        }
    }
    &__more {
        text-decoration: none;
        float: left;
        font-size: 14px;
        font-weight: bold;
        color: #AD4699;
        cursor: pointer;
    }
}

.active-facets {

    span {
        padding: 10px 70px 12px 30px;
        font-size: 18px;
        cursor: pointer;
        display: inline-block;
        margin-bottom: 30px;
        color: $color-primary;
        border: 1px solid $color-primary;
        background-color: white;
        position: relative;
        margin-right: 20px;
        text-transform: capitalize;
        &:after {
            position: absolute;
            content: "";
            display: block;
            background: url('{{file_dest}}/delete-button.png') no-repeat 0 50%;
            width:10px;
            height: 10px;
            top: calc(50% - 6px);
            right: 20px;

        }
    }
}


/* Loader */
.loader-cloak{
    background: rgba(255,255,255,.7);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999; // Change to appropriate z-index of your project
}

.loader,
.loader::after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.loader {
    margin: 0;
    font-size: 10px;
    position: absolute;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(0,54,95, 0.2);
    border-right: 1.1em solid rgba(0,54,95, 0.2);
    border-bottom: 1.1em solid rgba(0,54,95, 0.2);
    border-left: 1.1em solid #00365F;
    -webkit-transform: translateZ(0), translateX(-50%), translateY(-50%);
    -ms-transform: translateZ(0), translateX(-50%), translateY(-50%);
    transform: translateZ(0), translateX(-50%), translateY(-50%);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    top: 50%;
    left: 50%;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}