/* Module: S */
.video-content {
    &__wrapper {
        max-width: pxToRem(1133);
        margin: 0 auto;
        position: relative;
        padding: 48.25% 0 0 0;
        iframe {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
        }
    }
}

.video-content.container {
    max-width: 100%;
    padding: 0;
}